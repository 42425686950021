<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.keyword"
            size="medium"
            placeholder="活动名称"
          />
        </nav>
        <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >活动时间：</label
        >
        <nav class="input">
          <el-date-picker
            v-model="form_2.activityDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            size="medium"
          />
        </nav>

        <nav class="input" style="width: 123px;">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>
        <nav class="input" style="width: auto;">
          <el-button
            v-show="$store.state.btn_if.indexOf('rotary_add') != -1"
            type="primary"
            size="small"
            @click="newActivities"
            >{{ '新增活动' }}</el-button
          >
        </nav>
        <nav class="input" style="width: auto;">
          <el-button type="primary" size="small" @click="allData">{{
            '所有抽奖记录'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column type="selection"></el-table-column> -->
        <el-table-column prop="id" width="100" label="编号" />
        <el-table-column prop="name" label="活动名称" />
        <el-table-column prop="sunCode" label="活动入口" width="200px">
          <template slot-scope="{ row }">
            <!-- <img style="width:170px;" :src="baseurl + yu + row.sunCode" alt="" /> -->
            <p v-if="row.h5">
              <a :href="row.h5" target="_blank">(H5入口)</a>
            </p>
            <p v-if="row.sunCode">
              <a :href="baseurl + yu + row.sunCode" target="_blank"
                >(小程序入口)</a
              >
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="beginTime" label="活动时间">
          <template slot-scope="{ row }">
            <span>{{ row.beginTime }}至{{ row.endTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="drawNum" label="抽奖人数" />
        <el-table-column prop="prizeNum" label="中奖人数" />
        <el-table-column prop="prizeNum" label="活动状态">
          <template slot-scope="{ row }">
            <el-tag type="warning">{{ f_status(row.status) }}</el-tag>
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="params"
          label="参数"
          :show-overflow-tooltip="true"
        > -->
        <!-- </el-table-column> -->
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-show="$store.state.btn_if.indexOf('rotary_show_data') != -1"
              type="text"
              icon="el-icon-camera"
              size="mini"
              class="green"
              @click="viewTheData(row)"
              >查看数据</el-button
            >
            <el-button
              v-show="$store.state.btn_if.indexOf('rotary_edit') != -1"
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="skyblue"
              @click="theEditor(row.id)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="form_2.page"
        :total="total"
        :page-size="form_2.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btn: true,
      list: [],
      form_2: {
        page: 1,
        pageSize: 10,
        keyword: '',
        activityDate: []
      },
      form: {
        id: '',
        linkName: '',
        linkUrl: ''
      },
      form_1: {
        linkName: '',
        linkUrl: ''
      },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      dialogVisible: false,
      dialogVisiblexui: false
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    rowClass() {
      return 'background:#F5F7FA;'
    },
    newActivities() {
      this.$router.push('/rotary_list_add')
    },
    f_status(val) {
      if (val == 0) {
        return '未开始'
      } else if (val == 1) {
        return '进行中'
      } else if (val == 2) {
        return '结束'
      }
    },
    viewTheData(row) {
      this.$router.push({
        path: '/rotary_list_data',
        query: {
          id: row.id
        }
      })
    },
    theEditor(id) {
      this.$router.push({
        path: '/rotary_list_add',
        query: {
          id: id
        }
      })
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.getlist()
    },
    allData() {
      this.$router.push({
        path: '/rotary_list_data'
      })
    },
    getlist() {
      this.$http
        .get('/admin/Rotary/getList', {
          params: {
            ...this.form_2,
            activityDate: this.form_2.activityDate
              ? this.form_2.activityDate.join('~')
              : ''
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res.data.list)
            this.list = res.data.list
            this.total = res.data.totalCount
            console.log(this.list)
          }
        })
    },
    edit(e) {
      this.form.id = e
      this.dialogVisible = true
      this.$http
        .get('/admin/FriendLink/getById?id=' + e)
        .then(({ data: res }) => {
          this.form.linkName = res.data.linkName
          this.form.linkUrl = res.data.linkUrl
        })
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/FriendLink/edit', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.getlist()
              this.$message.success(res.message)
              this.dialogVisible = false
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    // this
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/FriendLink/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.form_2.page--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'activity_order_num'
        } else {
          s = 'rl_create_time'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    handleSizeChange(e) {
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e) {
      this.form_2.page = e
      this.getlist()
    },
    subimt() {
      this.form_2.page = 1
      this.getlist()
    },
    addedit() {
      if (this.btn) {
        this.$http
          .post('/admin/FriendLink/add', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisiblexui = false
              location.reload()
            }
            this.btn = true
          })
      }
      this.btn = false
    }
  }
}
</script>

<style lang="less" scoped>
.upload {
  width: 200px;
  height: 200px;
}
.skyblue {
  color: skyblue;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
